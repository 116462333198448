$primary: #05367c;

.login {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100vh;
    font-family: "Montserrat";
    align-items: center;
    padding-bottom: 10px;
}

.login__form {
    display: flex;
    flex-direction: column;
    width: 500px;
}

.login__form .form-label {
    font-weight: 800 !important;
    color: $primary;
}

.container {
    height: inherit;
}

.row {
    height: inherit;
}

.left_login {
    background-color: $primary;
}

.logo {
    padding: 0;
    max-width: 100%;
}

.left_login blockquote {
    text-align: center;
    font: normal normal normal 16px/27px Montserrat;
    letter-spacing: -0.24px;
    color: #ffffff;
    opacity: 1;
    padding: 0 40px 0 40px;
    margin: 0px;
}

.quotes {
    color: white;
    padding: 10px;
}

.closing_quote {
    float: right;
}

.quote_mark {
    padding-top: 60vh;
}

.right_login {
    align-self: flex-end;
    padding: 0px;
    max-height: inherit;
    padding: 0px;
}

.login_form {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-items: center;
    font-size: 22px;
    max-width: 500px;
    min-height: 672px;
    margin: 0 auto;
    border-radius: 25px;
}

.login input {
    margin: 0px !important;
    background-color: #fafbfc !important;
    color: $primary;
    margin-top: 10px;
    width: 300px;
    height: 59px;
    font-size: 22px;
    margin-bottom: 14px;
    max-width: 527px;
    padding: 0 10%;
}

.login_eye {
    left: 440px;
    position: absolute;
    top: calc(59px / 2 - 15px);
    z-index: 1000;
}

.login_button {
    height: 59px;
    background-color: $primary !important;
}

.login_row {
    margin: 0px;
    padding: 0px;
}

.image_container {
    text-align: center;
}

.vl-right {
    border-right: 3px solid $primary;
    height: 20px;
}

.vl-left {
    border-left: 3px solid $primary;
    height: 20px;
}

.info {
    display: inline-flex;
}
.info span {
    padding: 10px;
}

.info div {
    width: 33%;
}
