$active: #7BB7F0;
$primary: #05367C;
$secondary: hsl(209, 57%, 49%);


.dropdown-item:hover{
    cursor: pointer !important;
}

.DateTimebutton {
    justify-content: center;
}


.edit-flat{
    background-color: $primary !important;
    color: white !important;
    border-radius: 0rem !important;
    font-weight: 600 !important;

    &:hover{
        background-color:$primary;
        color:white;
    }
}

.btn-succes-focus-visible:focus-visible{
    outline: none;
}

.search-flat{
    background-color: $primary !important;
    color: white !important;
    font-weight: 600 !important;
    border: 1px solid $primary !important;
    font-size: initial;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;

    &:hover{
        background-color:$primary;
        color:white;
    }
}
