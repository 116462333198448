$primary: #05367c;
$secondary: hsl(209, 57%, 49%);
$active: #7bb7f0;

.top_bar {
    height: 50px;
    background-color: $primary;
}

.side_bar {
    background-color: $primary;
    width: 220px;
    color: white;
}

.content {
    width: -webkit-fill-available;
    background-color: $secondary;
    width: -moz-available;
    overflow-x: hidden;
}

.side_bar .active {
    background: #7bb7f0 0% 0% no-repeat padding-box;
    opacity: 1;
}

.sidebar_list {
    height: auto;
    width: 100%;
    padding: 0;
}

.sidebar_list_row a {
    width: 100%;
    height: 50px;
    list-style-type: none;
    margin: 0%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Montserrat";
    text-decoration: none;
    color: white;
    font-size: 18px;
}

.sidebar_list .sidebar_list_row:hover {
    cursor: pointer;
    background-color: $active;
}

.sidebar_list_row #icon {
    flex: 30%;
    display: grid;
    place-items: center;
}

.sidebar_list_row #title {
    flex: 70%;
}

#active {
    background-color: $active;
}
