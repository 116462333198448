$primary: #05367c;
$active: #7bb7f0;
$secondary: hsl(209, 57%, 49%);
$yellow: #ffcd01;

table:hover {
    cursor: default;
}
.operators_container {
    padding: 20px;

    label {
        color: white;
        font-weight: 600;
    }

    input {
        background-color: $primary;
        color: white;
        border: none;
    }

    .formPlaintextEmail {
        background-color: $primary !important;
    }

    .form-control:disabled,
    .form-control[readonly] {
        background-color: $primary;
        opacity: 1;
        color: white;
        border: none;
    }

    button {
        background-color: $primary;
        color: white;
        border-color: $primary;
        padding: 0.5% 1.5% 0.5% 1.5% !important;
    }

    button:hover {
        background-color: $active;
        border-color: $active;
    }
}

.table-design {
    table {
        border-spacing: 0;
        border: 1px solid black;

        tr {
            :last-child {
                td {
                    border-bottom: 0;
                }
            }
        }

        th,
        td {
            margin: 0;
            padding: 0.5rem;
            border-bottom: 1px solid black;
            border-right: 1px solid black;

            :last-child {
                border-right: 0;
            }
        }
    }

    .pagination {
        padding: 0.5rem;
        width: auto;
        display: inline-flex;
        margin: 0px;
    }

    .react-bootstrap-table-pagination-total {
        color: #ffffff80;
    }

    .page-link {
        background-color: transparent !important;
        border-color: transparent !important;
        color: white !important;
        padding: 0.2rem 0.5rem !important;

        &:hover {
            cursor: pointer;
            background-color: lightblue !important;
            background-color: lightblue !important;
            border-radius: 5px;
        }
    }

    .active .page-link {
        background-color: $primary !important;
        border-radius: 5px;
    }

    .react-bs-table-sizePerPage-dropdown {
        margin-right: 0.4rem;

        #pageDropDown {
            background-color: transparent !important;
            border: none !important;
        }

        #pageDropDown:hover {
            border: none !important;
        }

        #pageDropDown:active {
            border: none !important;
        }

        #pageDropDown:focus {
            box-shadow: none !important;
        }

        .dropdown-item {
            background-color: $primary;
            border: none !important;
        }

        .dropdown-menu {
            min-width: fit-content;
            padding: 0px;
            border: none;

            a {
                text-decoration: none;
            }
        }

        .dropdown-item:hover {
            background-color: $active !important;
        }
    }

    .pagination-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 40px;
    }

    .sortBy-flat {
        background-color: $primary;
        color: white;
        border: 1px solid $primary;
        border-radius: 5px;
        width: 100px !important;
    }

    .sortBy-flat::after {
        display: none;
    }
}

.table-design table {
    color: white;
    border: none !important;

    thead {
        border: 1px solid $primary !important;
        background-color: $primary;
        tr {
            border: none;
            th {
                border: none;
            }
        }
    }

    tbody {
        border: none !important;

        tr {
            border-left: none !important;
            border-right: none !important;
            border-top: none !important;
            border-bottom: 1px solid white !important;

            td {
                border: none;
            }
        }
    }

    td {
        vertical-align: middle;
        text-align: center;
    }

    th {
        text-align: center;
    }

    .delete-flat {
        background-color: #f24668;
        color: white;
    }

    .edit-flat {
        background-color: #62bb47;
        color: white;
        margin-right: 10px;
    }

    .secondary-flat {
        color: white;
        background-color: $yellow;
    }
}

.add-flat {
    background-color: $primary !important;
    color: white !important;
    font-size: 1.5rem !important;
    padding: 0px 10px 3px 10px !important;
}

.add-modal {
    .delete-flat {
        background-color: #f24668;
        color: white;
        padding: 0.5rem 2rem;

        &:hover {
            background-color: $active;
            color: white;
        }
    }

    .edit-flat {
        background-color: #62bb47;
        color: white;
        margin-right: 10px;
        padding: 0.5rem 2rem;

        &:hover {
            background-color: $active;
            color: white;
        }
    }

    .modal-content {
        border: 0px solid transparent !important;
        background-color: $secondary;
    }

    .modal-footer {
        border-top: 0 none;
        justify-content: center;
    }

    .modal-header {
        border: 0 none;
        background-color: $primary;
        color: white;
        justify-content: center;

        button {
            display: none;
        }
    }
}

.dropdownSortBy div {
    min-width: fit-content;
    padding: 0px;
    border: none;
    color: white;

    a {
        text-decoration: none;
        color: white;
    }

    a:hover {
        background-color: $active;
        color: white;
    }

    .dropdown-item {
        background-color: $primary;
        border: none !important;
    }
}
