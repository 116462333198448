$primary: #05367C;
$active: #7BB7F0;

.operators_container{
    padding:20px;

    label{
        color:white;
        font-weight: 600;
    }

    input{
        background-color: $primary;
        color: white;
        border: none;
    }

    .formPlaintextEmail{
        background-color: $primary !important;
    }

    .form-control:disabled, .form-control[readonly] {
        background-color: $primary;
        opacity: 1;
        color: white;
        border: none;
    }

    button{
        background-color: $primary;
        color: white;
        border-color: $primary;
        padding: .5% 1.5% .5% 1.5% !important;
    }

    button:hover{
        background-color: $active;
        border-color: $active;
    }

    textarea{
        background-color: $primary ;
        border-color: $primary ;
        color: white;
    }
}