@import "@fontsource/montserrat";
$active: #7bb7f0;
$primary: #05367c;
$secondary: hsl(209, 57%, 49%);
$yellow: #ffcd01;

:root {
    --s-loader-wrapper-bg-color: rgb(21, 22, 43);
    --s-loader-width: 60px;
    --s-loader-height: var(--s-loader-width);
    --s-loader-border-color-trans: rgba(29, 161, 242, 0.2);
    --s-loader-border-color-full: rgb(29, 161, 242);
}

html {
    height: 100vh;
    overflow: hidden;
}

body {
    margin: 0;
    font-family: "Montserrat";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: inherit;
}

#root {
    height: inherit;
}

// change the theme
$theme-colors: (
    "primary": #05367c,
    "danger": #ff4136,
);

.loader-container {
    height: inherit;
    background-color: $secondary;

    div {
        position: absolute;
        top: calc(50% - 2.5%);
        left: calc(50% - 2.5%);
    }
}

#tabs {
    .outer-tabs {
        border-bottom: 10px solid #05367c !important;
        margin: 0px !important;

        .nav-link {
            background-color: $primary !important;
            border-color: $primary !important;
            color: white !important;
            font-family: "Montserrat" !important;
            border-top-left-radius: 10px !important;
            border-top-right-radius: 10px !important;
            font-weight: 800 !important;
            padding-bottom: 7px !important;
        }

        .nav-tabs {
            margin: 0 !important;
        }

        button {
            padding-left: 30px !important;
            padding-right: 30px !important;
        }
    }

    .inner-tabs {
        border: 0 !important;

        li button {
            border-bottom: none !important;
            border-radius: 0px !important;
            border: 0 !important;
        }

        .nav-tabs {
            border: 0 !important;
            margin: 0 !important;
            border-bottom: none !important;
        }

        .nav-link {
            border: 0 !important;
            color: white !important;
            font-family: "Montserrat" !important;
            font-weight: 800 !important;
            padding: 0.8rem 30px;
        }

        .nav-link:hover {
            background-color: $active;
        }

        .inner-line {
            background-color: $primary !important;
            .active {
                border: 0px !important;
                background-color: $active !important;
                margin: 0px !important;
                padding-left: 30px !important;
                padding-right: 30px !important;
            }
        }
    }
}

.input-error {
    color: white !important;
    font-weight: 800;
}

.addTab {
    right: 0px;
    position: absolute;
    top: 50px;
    padding: 0px !important;
    margin-bottom: 0px !important;
    color: white;

    &:hover {
        background-color: transparent !important;
        cursor: default;
    }

    button:hover {
        background-color: #7bb7f0 !important;
    }
}

.addToSecondTab {
    right: 0px;
    position: absolute;
    top: 100px;
    padding: 0px !important;
    margin-bottom: 0px !important;
    color: white;

    &:hover {
        background-color: transparent !important;
        cursor: default;
    }

    button:hover {
        background-color: #7bb7f0 !important;
    }
}

.rule_container {
    padding: 20px;

    label {
        color: white;
        font-weight: 600;
    }

    input {
        background-color: $primary;
        color: white;
        border: none;
    }

    .formPlaintextEmail {
        background-color: $primary !important;
    }

    .form-control:disabled,
    .form-control[readonly] {
        background-color: $primary;
        opacity: 1;
        color: white;
        border: none;
    }

    .DateTimebutton {
        background-color: $primary;
        color: white;
        border-color: $primary;
        padding: 0.5% 1.5% 0.5% 1.5% !important;
        width: -webkit-fill-available;
        height: 38px;
    }

    .DateTimebutton:hover {
        background-color: $active;
        border-color: $active;
        color: white;
    }

    textarea {
        background-color: $primary;
        border-color: $primary;
        color: white;
    }
}

.react-datepicker-wrapper {
    padding: 10px !important;
}

.w-fit-content {
    width: fit-content !important;
}

.hand-cursor-table tr:hover {
    cursor: pointer;
}

*:focus,
*:focus-visible {
    outline: none !important;
    box-shadow: none !important;
}

.logout-button:hover {
    svg {
        fill: red;
    }
    background-color: transparent !important;
}

.location-main-tab {
    display: table !important;
    width: 100%;

    :hover {
        cursor: default !important;
    }

    li {
        display: table-cell;

        button {
            width: -webkit-fill-available;
        }
    }
}

button:hover {
    background-color: lightblue !important;
    border-color: transparent !important;
}
